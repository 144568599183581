import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c30f930"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled", "isAdd"]
const _hoisted_2 = ["for"]
const _hoisted_3 = ["required", "readonly", "disabled", "value", "id"]
const _hoisted_4 = ["required", "readonly", "disabled", "value", "id"]
const _hoisted_5 = ["required", "readonly", "value", "id"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["required", "disabled", "id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "root",
    type: _ctx.$attrs.type,
    disabled: _ctx.disabled,
    isAdd: _ctx.isAdd
  }, [
    (_ctx.$attrs.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.elementId,
          class: "label"
        }, _toDisplayString(_ctx.$attrs.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    (
        _ctx.$attrs.type === 'text' ||
        _ctx.$attrs.type === 'password' ||
        _ctx.$attrs.type === 'email' ||
        _ctx.$attrs.type === 'checkbox' ||
        _ctx.$attrs.type === 'number' ||
        _ctx.$attrs.type === 'file'
      )
      ? (_openBlock(), _createElementBlock("input", _mergeProps({
          key: 1,
          class: "native-input"
        }, _ctx.$attrs, {
          required: _ctx.required,
          readonly: _ctx.readonly,
          disabled: _ctx.disabled,
          value: _ctx._value,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event))),
          id: _ctx.elementId,
          ref: "inputRef"
        }), null, 16, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.$attrs.type === 'textarea')
      ? (_openBlock(), _createElementBlock("textarea", _mergeProps({
          key: 2,
          class: "native-input"
        }, _ctx.$attrs, {
          required: _ctx.required,
          readonly: _ctx.readonly,
          disabled: _ctx.disabled,
          value: _ctx._value,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput($event))),
          id: _ctx.elementId
        }), null, 16, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.$attrs.type === 'select')
      ? (_openBlock(), _createElementBlock("select", _mergeProps({
          key: 3,
          class: "native-input"
        }, _ctx.$attrs, {
          required: _ctx.required,
          readonly: _ctx.readonly,
          value: _ctx._value,
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSelectChange($event))),
          id: _ctx.elementId
        }), [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              value: option.value,
              key: option.value
            }, _toDisplayString(option.name), 9, _hoisted_6))
          }), 128))
        ], 16, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.$attrs.type === 'date')
      ? (_openBlock(), _createElementBlock("input", _mergeProps({
          key: 4,
          class: "native-input"
        }, _ctx.$attrs, {
          required: _ctx.required,
          disabled: _ctx.disabled,
          id: _ctx.elementId,
          value: _ctx.dateDisplayValue,
          type: "text",
          isDate: "",
          ref: "inputRef"
        }), null, 16, _hoisted_7))
      : _createCommentVNode("", true),
    (_ctx.isAdd && _ctx.$attrs.type !== 'select' && !!_ctx._value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInputAdd())),
          class: "add-button"
        }, " Add "))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}