import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import moment from "moment";

@Options({
  props: {
    required: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    options: Array,
    isDateRange: Boolean,
    isAdd: Boolean,
    addMinCount: Number,
    addCount: Number,
    value: Object as PropType<any>,
  },
})
export default class FormField extends Vue {
  options = [] as { name: string; value: string }[];
  isDateRange = false;
  isAdd = false;
  addMinCount = 0;
  addCount = 0;
  dateRange = {} as { start?: string; end?: string };
  dateDisplayValue = "";
  elementId = "";
  _value: any;
  value: any;

  created() {
    this._value = this.value;
    this.elementId = `${Date.now().toString()}-${Math.random()}}`;

    this.$watch("addCount", () => {
      this.setAddValidity();
    });

    this.$watch("addMinCount", () => {
      this.setAddValidity();
    });
  }

  mounted() {
    try {
      if (this.$attrs.type === "date") {
        const formatter = (date?: string) => moment(date).format("MM/DD/YYYY");

        if (this._value) this.dateDisplayValue = formatter(this._value);

        const jQuery = (window as any).jQuery;
        const dateEl = jQuery(this.$refs.inputRef);
        dateEl.daterangepicker({
          autoUpdateInput: false,
          linkedCalendars: false,
          singleDatePicker: !this.isDateRange,
          autoApply: false,
          parentEl: "ion-modal",
          locale: {
            cancelLabel: "Clear",
            applyLabel: this.isDateRange ? "Select range" : "Select date",
          },
        });

        dateEl.on("apply.daterangepicker", (ev: Event, picker: any) => {
          if (this.isDateRange) {
            this.dateRange.start = picker.startDate.toISOString().split("T")[0];
            this.dateRange.end = picker.endDate.toISOString().split("T")[0];
            this.dateDisplayValue = `${formatter(
              this.dateRange.start
            )} - ${formatter(this.dateRange.end)}`;

            this.$emit("change", { ...this.dateRange });
          } else {
            this.dateRange.start = picker.startDate.toISOString().split("T")[0];
            this.dateDisplayValue = formatter(this.dateRange.start);
            this.$emit("change", this.dateRange.start);
          }
        });

        dateEl.on("cancel.daterangepicker", (ev: Event, picker: any) => {
          this.dateDisplayValue = "";

          this.dateRange.start = undefined;
          this.dateRange.end = undefined;

          if (this.isDateRange) this.$emit("change", { ...this.dateRange });
          else this.$emit("change", null);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  onInput($event: Event) {
    this._value = ($event.target as any).value;
    const inputElm = $event.target as HTMLInputElement;
    this.setAddValidity();
    this.$forceUpdate();
  }

  setAddValidity() {
    const inputElm = this.$refs.inputRef as HTMLInputElement;
    if (this.isAdd) {
      console.log(this.addCount, this.addMinCount);
      if (this.addCount < this.addMinCount) {
        let message = "";
        if (this._value)
          message = `Click the Add button on right to add this entry`;
        else message = `Atleast ${this.addMinCount} required`;
        inputElm.setCustomValidity(message);
      } else {
        inputElm.setCustomValidity("");
      }
    }
  }

  onInputAdd() {
    this.setAddValidity();

    this.$emit("add", this._value);
    this._value = "";
    this.$forceUpdate();
  }

  onSelectChange($event: Event) {
    this._value = ($event.target as any).value;

    if (this._value && this.isAdd) {
      this.$emit("add", this._value);
      this._value = "";
      this.$forceUpdate();
    }
  }
}
