import * as models from "../models";
import Notifications from "../components/Notifications";

declare const window: any;

export function calcAlternativeIndex(index: number): "a" | "b" {
  const i = index % 10;

  let isAlt = true;

  const indexString = String(index);
  const f = Number(indexString[indexString.length - 2] || 0);

  if ((f % 2 === 0 && i !== 0) || (f % 2 === 1 && i === 0)) {
    isAlt = true;
  } else isAlt = false;

  const aValue = isAlt ? "a" : "b";
  const bValue = isAlt ? "b" : "a";

  if (i % 2 == 1) {
    if ([1, 5, 9].includes(i)) return aValue;
    else if ([3, 7].includes(i)) return bValue;
  } else if (i === 0) return aValue;

  return calcAlternativeIndex(index - 1);
}

export function openMenu() {
  const elm = document.querySelector(".left-slider")!;
  elm.setAttribute("ismenuopen", "true");
}

export function closeMenu() {
  const elm = document.querySelector(".left-slider")!;
  elm.setAttribute("ismenuopen", "false");
}

export function isMenuOpen() {
  const elm = document.querySelector(".left-slider")!;
  return elm.getAttribute("ismenuopen") === "true";
}

export async function showProfileWarningOnce(
  member: models.Member,
  $router: any
) {
  if (
    member &&
    member.id &&
    !member.profile_complete &&
    !window.profileWarningAlreadyShown
  ) {
    window.profileWarningAlreadyShown = true;
    const yes = await Notifications.alert<boolean>(
      {
        variant: "warning",
        title: "Your profile is incomplete",
        message:
          "Your profile will only be visible to other members once it's complete.",
        backdropDismiss: false,
      },
      [
        { text: "Complete profile", value: true },
        { text: "Later", value: false },
      ]
    );

    if (yes === true) $router.push("/profile/edit");
  }
}

export function hideMessagesWindow(iframe: HTMLIFrameElement) {
  iframe.style.setProperty("display", "none");
}

export function showMessagesWindow(iframe: HTMLIFrameElement) {
  iframe.style.setProperty("display", "initial");
}

export function setMessageWindowOnLocationChange(iframe: HTMLIFrameElement) {
  if (window.location.pathname.startsWith("/messages"))
    showMessagesWindow(iframe);
  else hideMessagesWindow(iframe);
}

export function gaPageView(url: string, title?: string) {
  try {
    document.title =
      title || "CA² | Collaborate With Researchers Anytime Anywhere";
    const gtag = window.gtag;
    gtag("config", process.env.GTAG_ID, {
      page_path: url,
      page_title: title || url,
    });
  } catch (error) {
    console.log(error);
  }
}

export function gaEvent(
  action: models.GA_ACTIONS,
  category: models.GA_CATEGORIES,
  label?: string | number,
  value?: number
) {
  try {
    const gtag = window.gtag;
    gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  } catch (error) {
    console.log(error);
  }
}

export enum EventTypes {
  connectionStatusUpdated = "connectionStatusUpdated",
  unReadMessage = "unReadMessage",
  profileUpdate = "profileUpdate",
  memberUpdate = "memberUpdate",
  profileApiLoaded = "profileApiLoaded",
  statsUpdated = "statsUpdated",
}

export class Events {
  static emit(eventName: EventTypes, data: any) {
    const event = new CustomEvent(eventName, {
      detail: data,
    });

    document.dispatchEvent(event);
  }

  static on(eventName: EventTypes, listener: any) {
    document.addEventListener(eventName, listener);
  }

  static off(eventName: EventTypes, listener: any) {
    document.removeEventListener(eventName, listener);
  }
}

export function waitFor(seconds: number) {
  return new Promise((res) => setTimeout(res, seconds * 1000));
}
