import { Vue, Options } from "vue-class-component";
import { ResearchCommunityApi } from "../../services/http";
import Notifications from "../../components/Notifications";
import FormField from "../../components/FormField";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { JobType } from "../../models";

@Options({
  components: {
    FormField,
    Header,
    Footer,
  },
})
export default class AddResearchCommunity extends Vue {
  formData = {
    name: "",
    tag_line: "",
    is_remote: false,
    external_link: "",
    target_audience: "",
    added_by_public: true,
    tags: [] as string[],
  };

  getRedirectUrl(): string | undefined {
    const query = this.$route.query;
    if (query && query.redirect) return query.redirect as string;
  }

  async onFormSubmit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    const form = this.$refs["form"] as HTMLFormElement;
    //const logo = form.querySelector(`input[name="logo"]`)! as HTMLInputElement;
    const formData = new FormData();
    Object.keys(this.formData).forEach((key) => {
      formData.set(key, (this.formData as any)[key]);
    });

    //formData.set("logo", logo.files!.item(0)!);

    const job = await ResearchCommunityApi.add(formData);

    Notifications.alert(
      "Thanks for this posting, this will be reviewed by CA² and added to the platform in the next few days",
      [{ text: "Okay", value: "" }]
    );
  }

  onInputUpdate(
    name: "name" | "tag_line" | "target_audience" | "external_link",
    inputEvent: InputEvent
  ) {
    const inputEl = inputEvent.target as HTMLInputElement;
    this.formData[name] = inputEl.value;
  }

  onCheckBoxChange(name: "is_remote", inputEvent: InputEvent) {
    const inputEl = inputEvent.target as HTMLInputElement;
    this.formData[name] = inputEl.checked;
  }

  onAddNewTag(tag: string) {
    const tags = this.formData.tags || [];
    if (!tags.includes(tag)) tags.push(tag);
    this.formData.tags = tags;

    this.$forceUpdate();
  }

  onRemoveTag(tag: string) {
    const tags = this.formData.tags || [];
    const index = tags.indexOf(tag);
    if (index >= 0) tags.splice(index, 1);
    this.formData.tags = tags;

    this.$forceUpdate();
  }
}
