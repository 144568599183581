import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b5b289e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.member.profile_pic
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.member.name), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.member.school), 1),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
    }, [
      _createVNode(_component_router_link, {
        class: "profile-link",
        to: _ctx.member.profile_complete ? '/profile' : '/profile/edit',
        onClickCapture: _ctx.onProfileClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.member.profile_complete ? "View profile" : "Complete profile"), 1)
        ]),
        _: 1
      }, 8, ["to", "onClickCapture"])
    ])
  ]))
}