import { Vue, Options } from "vue-class-component";

import {
  Member,
  CONNECT_STATUS_TYPE,
  CONNECT_ACTIONS,
  GA_ACTIONS,
  GA_CATEGORIES,
} from "../../models";
import { ProfileApi, MemberApi, AuthApi } from "../../services/http";
import { MemberListActions, MemberConnectActions } from "../../services/data";
import { Events, EventTypes, gaEvent } from "../../services/utils";
import { PromptProfileCompletion } from "../../services/prompt-profile-completion";
import { filters } from "../../filters";

import LeftSideMenu from "../../components/LeftSideMenu";
import Notifications from "../../components/Notifications";
import FormField from "../../components/FormField";

import { SkillColor } from "../../services/data";

@Options({
  components: {
    LeftSideMenu,
    FormField,
  },
})
export default class Profile extends Vue {
  private member!: Member;
  private isOwner = false;
  private memberActions: any[] = [];
  private memberConnectActions: any;

  skillColor = SkillColor;
  CONNECT_STATUS_TYPE = CONNECT_STATUS_TYPE;
  CONNECT_ACTIONS = CONNECT_ACTIONS;

  async created() {
    this.setMember();

    this.memberActions = MemberListActions;
    this.memberConnectActions = MemberConnectActions;

    this.$watch("$route", (to: any) => {
      if (
        to.meta.member &&
        to.fullPath.startsWith("/profile") &&
        !to.fullPath.startsWith("/profile/edit")
      ) {
        this.setMember();
        this.$forceUpdate();
      }
    });
  }

  mounted() {
    Events.on(EventTypes.profileUpdate, this.onMemberUpdate);
  }

  beforeUnmount() {
    Events.off(EventTypes.profileUpdate, this.onMemberUpdate);
  }

  onMemberUpdate(event: CustomEvent) {
    this.setMember();
    const newMember = event.detail as Member;

    if (this.isOwner) {
      Object.assign(this.$route.meta.member, newMember);
      this.setMember();
    }
    this.$forceUpdate();
  }

  setMember() {
    this.member = this.$route.meta.member as Member;
    this.updateOwner();
    this.updateSections();
  }

  onActionClick(actionType: CONNECT_ACTIONS) {
    switch (actionType) {
      case CONNECT_ACTIONS.connect:
        gaEvent(GA_ACTIONS.make_connection_request, GA_CATEGORIES.connections);
        this.connect();
        break;
      case CONNECT_ACTIONS.accept:
        gaEvent(GA_ACTIONS.accept_connection, GA_CATEGORIES.connections);
        this.accept();
        break;
      case CONNECT_ACTIONS.decline:
        gaEvent(GA_ACTIONS.decline_connection, GA_CATEGORIES.connections);
        this.decline();
        break;
      case CONNECT_ACTIONS.cancel:
        gaEvent(GA_ACTIONS.cancel_connection, GA_CATEGORIES.connections);
        this.cancel();
        break;
      case CONNECT_ACTIONS.remove:
        gaEvent(GA_ACTIONS.remove_connection, GA_CATEGORIES.connections);
        this.remove();
        break;
    }
  }

  onMessageMember(event: Event) {
    gaEvent(GA_ACTIONS.message_member, GA_CATEGORIES.messaging);
  }

  onEditProfile(event: Event) {
    gaEvent(GA_ACTIONS.edit_profile, GA_CATEGORIES.profiles);
  }

  updateOwner() {
    this.isOwner = !!(AuthApi.member && AuthApi.member.id === this.member.id);
  }

  async connect() {
    if (
      !AuthApi.member!.profile_complete &&
      !(await PromptProfileCompletion(AuthApi.member!, {
        title: "Profile update required",
        subtitle: "Update your profile before initiating a connection request",
      }))
    )
      return Notifications.toast({
        title:
          "You won’t be able to initiate a connection request unless your profile is updated",
        duration: 3000,
      });

    const member = await MemberApi.requestConnection(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.connect, member);
  }

  async cancel() {
    const member = await MemberApi.cancelConnectionRequest(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.cancel, member);
  }

  async accept() {
    const member = await MemberApi.acceptConnection(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.accept, member);
  }

  async decline() {
    const member = await MemberApi.declineConnection(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.decline, member);
  }

  async remove() {
    const confirm = await Notifications.alert<boolean>(
      `Are you sure you want to remove ${this.member.name} as a connection?`,
      [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ]
    );
    if (confirm !== true) return;

    const member = await MemberApi.removeConnection(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.remove, member);
  }

  async updatedConnectStatus(actionType: CONNECT_ACTIONS, member: Member) {
    Events.emit(EventTypes.connectionStatusUpdated, member);

    let message = "";
    switch (actionType) {
      case CONNECT_ACTIONS.accept:
        message = `You are now connected to ${member.name}`;
        break;
      case CONNECT_ACTIONS.connect:
        message = `Connection request sent to ${member.name}`;
        break;
      case CONNECT_ACTIONS.cancel:
        message = `Your connection request has been withdrawn`;
        break;

      case CONNECT_ACTIONS.remove:
        message = `This connection has been removed`;
        break;

      case CONNECT_ACTIONS.decline:
        message = `You have declined this connection request`;
        break;
    }

    if (message) Notifications.toast(message);

    this.member = { ...member };
    this.$forceUpdate();
  }

  research_interests_text = "";
  collaboration_type_text = "";
  preferred_authorship_text = "";
  group_size_text = "";
  project_progress_text = "";
  projectProgressCSSWidth = "0%";

  availability_dates_text = "";
  timezones_text = "";

  updateSections() {
    this.research_interests_text = filters.InterestsText(
      this.member.research_preference.interests
    );

    this.collaboration_type_text =
      filters.CollaborationText(this.member.research_preference) || "N/A";

    this.preferred_authorship_text = filters.AuthorshipText(
      this.member.research_preference.author_main
    );

    this.group_size_text = filters.GroupSizeText(
      this.member.research_preference.group_size
    );

    this.project_progress_text = filters.ProjectProgressText(
      this.member.research_preference.project_progress
    );

    this.availability_dates_text = filters.AvailabilityText(
      this.member.research_preference,
      "From ",
      "to"
    );

    this.timezones_text = filters.TimeZonesText(
      this.member.research_preference.timezones,
      "<br/><br/>"
    );

    if (this.member.research_preference.project_running) {
      this.projectProgressCSSWidth = `${
        this.member.research_preference.project_progress || 0
      }%`;
    } else {
      this.projectProgressCSSWidth = "0%";
    }
  }
}
