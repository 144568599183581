import { Vue, Options } from "vue-class-component";
import { Member, ConnectionStats } from "../../models";
import { AuthApi, MemberApi } from "../../services/http";
import {
  Events,
  EventTypes,
  closeMenu,
  showProfileWarningOnce,
} from "../../services/utils";
import { Stats } from "../../services/data";

import ProfileCard from "../ProfileCard";
import Footer from "../Footer";
import Notifications from "../Notifications";

declare const window: any;

@Options({
  components: {
    ProfileCard,
    Footer,
  },
  props: {
    homeLink: String,
  },
})
export default class LeftSideMenu extends Vue {
  member: Member = null as any;
  visible = false;
  defaultHomeLink = "/?skip_redirect=true";

  static _menuItems = [
    {
      id: "feeds",
      name: "Feeds",
      icon: "/assets/icons/connections.svg",
      link: "/feeds",
      count: undefined,
    },

    {
      id: "home",
      name: "Search",
      icon: "/assets/icons/home.svg",
      link: "/home",
      count: undefined,
    },
    {
      id: "connections",
      name: "Connections",
      icon: "/assets/icons/connections.svg",
      link: "/connections",
      count: undefined,
    },
    {
      id: "jobs",
      name: "Jobs",
      icon: "/assets/icons/jobs.svg",
      link: "/jobs",
      count: undefined,
    },

    {
      id: "grants",
      name: "Grants",
      icon: "/assets/icons/jobs.svg",
      link: "/grants",
      count: undefined,
    },

    {
      id: "communities",
      name: "Communities",
      icon: "/assets/icons/communities.svg",
      link: "/communities",
      count: undefined,
    },

    {
      id: "conferences",
      name: "Conferences",
      icon: "/assets/icons/conferences.svg",
      link: "/conferences",
      count: undefined,
    },

    /**     {
      id: "libraries",
      name: "Libraries",
      icon: "/assets/icons/communities.svg",
      link: "/libraries",
      count: undefined,
    }, */
    {
      id: "messages",
      name: "Messages",
      icon: "/assets/icons/messages.svg",
      link: "/messages",
      count: undefined,
    },
  ] as {
    id: string;
    name: string;
    icon: string;
    link: string;
    count: number | undefined;
  }[];

  menuItems = LeftSideMenu._menuItems;

  async created() {
    await AuthApi.isLoggedIn();
    this.member = AuthApi.member!;
    this.onStatsUpdated({ detail: Stats } as any);
    console.log("loogged in", this.member);
  }

  mounted() {
    //console.log("Hello, Hey");
    Events.on(EventTypes.profileApiLoaded, this.onMemberUpdate);
    Events.on(EventTypes.statsUpdated, this.onStatsUpdated);
  }

  beforeUnmount() {
    Events.off(EventTypes.profileApiLoaded, this.onMemberUpdate);
    Events.off(EventTypes.statsUpdated, this.onStatsUpdated);
  }

  onStatsUpdated(event: CustomEvent) {
    const stats = event.detail as any;

    const messageItem = LeftSideMenu._menuItems.find(
      (m) => m.id === "messages"
    )!;
    messageItem.count = stats.unReadMessageCount;

    const connectionsItem = LeftSideMenu._menuItems.find(
      (m) => m.id === "connections"
    )!;
    connectionsItem.count =
      (stats.connectionStats.i_requested || 0) +
      (stats.connectionStats.other_requested || 0) +
      (stats.connectionStats.connected || 0);

    this.$forceUpdate();
  }

  onMemberUpdate(event: CustomEvent) {
    this.member = event.detail as Member;
    this.$forceUpdate();
  }

  profileLink = "/profile/edit";

  async onLogoutClick($event: Event) {
    closeMenu();

    $event.preventDefault();
    $event.stopPropagation();
    await AuthApi.logout();
    //this.$router.push("/")
    window.location.href = "/";
    window.location.reload();
  }
}
